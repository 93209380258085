







































































import Vue, { PropType } from 'vue'

import dateMixin from '@/mixins/dateMixin'
import suggestionsMixin from '../suggestionsMixin'
import { SuggestionDto, ServiceModelServicesDto, ProductOffersDto, VariantDataDto } from "@happytal/happydom-api"

import { HPopin, HBtn, HForm, HFormField, HSelect, HTextarea } from "@happytal/bo-ui-library"
import SuggestionCard from './/SuggestionCard.vue'

export default Vue.extend({
    mixins: [dateMixin, suggestionsMixin],
    model: {
        prop: 'value',
        event: 'input'
    },

    components: {
        HPopin,
        HBtn,
        HForm,
        HFormField,
        HSelect,
        HTextarea,
        SuggestionCard
    },

    data() {
        return {
            responseValue: null as null | { name: string; value: string },
            responseOptions: [
                {name: "Merci, je m'en occupe", value: 'FulfilledExternaly'},
                {name: "Produit/service déjà fourni à mon proche par mes soins", value: 'AlreadyFulfilled'},
                {name: "Je ne souhaite pas donner suite à cette demande", value: 'Refused'},
            ],
            comment: ''
        }
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        suggestion: {
            type: Object as PropType<SuggestionDto>,
            required: false,
            default: null
        }
    },

    computed: {
        btnDisabled(): boolean {
            return this.responseValue ? false : true
        },
        serviceModelServices(): ServiceModelServicesDto[] {
            return this.$store.getters["dataSuggestions/serviceModelServices"]
        },
        productOffers(): ProductOffersDto[] {
            return this.$store.getters["dataSuggestions/productOffers"]
        },
        variantData(): VariantDataDto[] {
            return this.$store.getters["dataSuggestions/variantData"]
        },
    },

    methods: {
        async sendSuggestionResponse(): Promise<void> {
            const payload = {
                residentId: this.$route.params.resident_id,
                suggestionId: this.suggestion.id,
                status: this.responseValue?.value || null,
                processedComment: this.comment
            }
            try {
                await this.$store.dispatch('dataSuggestions/sendSuggestionResponse', payload)
                this.closePopin()
            } catch (err) {
                console.error(err)
            }
        },
        closePopin(): void {
            this.$emit('input', false)
            const sto = setTimeout(() => {
                this.$emit('update:suggestion', null)
                clearTimeout(sto)
            }, 250)
            this.responseValue = null
            this.comment = ''
        }
    }
})
