import { render, staticRenderFns } from "./SuggestionSection.vue?vue&type=template&id=289c3d34&scoped=true&"
import script from "./SuggestionSection.vue?vue&type=script&lang=ts&"
export * from "./SuggestionSection.vue?vue&type=script&lang=ts&"
import style0 from "./SuggestionSection.vue?vue&type=style&index=0&id=289c3d34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289c3d34",
  null
  
)

export default component.exports