






























































































































import mixins from 'vue-typed-mixins'
import _ from "lodash"

import Routes from "@/constants/routes"

import suggestionsMixin from "./suggestionsMixin"

import { SuggestionDto, ProductOffersDto, ServiceModelServicesDto, VariantDataDto } from "@happytal/happydom-api"
import { ToastObject } from "vue-toasted/types/index"

import { HBtn, HRow, HCol } from "@happytal/bo-ui-library"
import BasketButton from "@/components/BasketButton.vue"
import NoteCard from "@/components/NoteCard.vue";
import SuggestionSection from "./components/SuggestionSection.vue"
import SuggestionCard from "./components/SuggestionCard.vue"
import SuggestionPopinDealing from "./components/SuggestionPopinDealing.vue"
import SuggestionPopinRedirectToBasket from "./components/SuggestionPopinRedirectToBasket.vue"
import DeleteSuggestionPopin from './components/DeleteSuggestionPopin.vue'

export default mixins(suggestionsMixin).extend({
  props: [
    'resident_id'
  ],
  components: {
    HBtn,
    HRow,
    HCol,
    BasketButton,
    SuggestionSection,
    SuggestionCard,
    NoteCard,
    SuggestionPopinDealing,
    SuggestionPopinRedirectToBasket,
    DeleteSuggestionPopin
  },
  data() {
    return {
      sidebar: false,
      modelPopinDealing: false,
      dataPopinDealing: null as null | SuggestionDto,
      modelPopinRedirectToBasket: false,
      deleteSuggestionPopinVisible: false,
      suggestionToDelete: null as null | Object
    }
  },

  computed: {
    isPendingPage(): boolean {
      return this.$route.name === Routes.ResidentSuggestionsPending
    },
    title(): string {
      return this.isPendingPage ? 'En attente de réponse' : 'À traiter'
    },
    descriptionCatalog(): string {
      return this.isPendingPage ? 'Produit ou service choisi directement dans le catalogue suggéré par le personnel de l’EHPAD.' : 'Le personnel de l’EHPAD vous suggère un produit ou service choisi directement dans le catalogue.'
    },
    isManager(): boolean {
      return this.$aclChecker.some(this.$acl, [
        'isTutor',
        'isFamily'
      ])
    },
    descriptionOpen(): string {
        if (this.isPendingPage) {
            return `Produit ou service via un commentaire libre suggéré par le personnel de l’EHPAD.`
        }
        let description = `Le personnel de l’EHPAD vous suggère un produit ou service renseigné via un commentaire libre.`
        if (this.isManager) {
            description += `<br />N'hésitez pas à parcourir notre catalogue pour faire parvenir ces produits en toute simplicité à votre ainé.`
        }
        return description
    },
    suggestions(): SuggestionDto[] {
      return this.$store.getters["dataSuggestions/suggestionsPendingOrdered"]
    },
    suggestionsLoading(): boolean {
      return this.$store.state.dataSuggestions.suggestionsLoading
    },
    productOffers(): ProductOffersDto[] {
      return this.$store.getters["dataSuggestions/productOffers"]
    },
    serviceModelServices(): ServiceModelServicesDto[] {
      return this.$store.getters["dataSuggestions/serviceModelServices"]
    },
    variantData(): VariantDataDto[] {
      return this.$store.getters["dataSuggestions/variantData"]
    },
    suggestionsFromCatalog(): SuggestionDto[] {
      return this.suggestions.filter(
        (item) => item.type === "Product" || item.type === "Service"
      )
    },
    suggestionsOpen(): SuggestionDto[] {
      return this.suggestions.filter((item) => item.type === "Open")
    },
    currentUserId(): string {
      return this.$store.getters["dataUser/getUserId"]()
    },
    hasCatalogSuggestions(): boolean {
      return !!this.suggestionsFromCatalog.length
    }
  },

  methods: {
    async onAddProductToBasket(suggestion: SuggestionDto): Promise<void> {
      if (suggestion.suggestionProduct && suggestion.suggestionProduct.productSku) {
        const productOffer = this.productOffers.find(po => po.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId))
        if (productOffer) {
          const offer = productOffer.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId)
          const draftProduct = {
            id: suggestion.suggestionProduct?.productSku || "",
            type: 'product',
            photo: suggestion.suggestionProduct?.imageUrl || null,
            title: suggestion.suggestionProduct?.title || "",
            description: suggestion.suggestionProduct?.description || "",
            product_quantity: suggestion.suggestionProduct?.quantity,
            legend: '',
            price: suggestion.suggestionProduct?.unitPrice || 0,
            frequency: suggestion.suggestionProduct?.frequencyInMonth,
            quantity: suggestion.suggestionProduct?.quantity || 0,
            meta: {
              product: productOffer.product,
              offer: offer,
              variantInfo: {
                data: this.variantData
              }
            }
          }
          await this.$store.dispatch('dataDrafts/add', {
            userId: this.currentUserId,
            residentId: this.resident_id,
            type: this.$_getOrderTypeFromBuyMode(suggestion),
            draftProduct
          })
        }
      }
      else if (suggestion.suggestionService) {
        const serviceModelService = this.serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code))
        if (serviceModelService) {
          const service = serviceModelService.services?.find(s => s.code === suggestion.suggestionService?.code)
          const draftProduct = {
            id: suggestion.suggestionService.code,
            type: 'service',
            photo: (serviceModelService.serviceModel?.publicData as any)?.imageUrl,
            title: suggestion.suggestionService.title,
            description: suggestion.suggestionService.description,
            legend: '',
            price: this.$_getSuggestionPrice(suggestion),
            frequency: suggestion.suggestionService.frequencyInMonth,
            quantity: suggestion.suggestionService.quantity,
            meta: {
              service: service,
              serviceModel: serviceModelService.serviceModel,
              options: suggestion.suggestionService.options
            }
          }
          await this.$store.dispatch('dataDrafts/add', {
            userId: this.currentUserId,
            residentId: this.resident_id,
            type: this.$_getOrderTypeFromBuyMode(suggestion),
            draftProduct
          })
        }
      }
      await this.sendSuggestionResponse(suggestion)

      //Open popin to redirect user when there is no more catalog suggestion
      if (!this.hasCatalogSuggestions) {
        const sto = setTimeout(() => {
          this.modelPopinRedirectToBasket = true
          clearTimeout(sto)
        }, 500)
      }
    },
    async sendSuggestionResponse(suggestion: SuggestionDto): Promise<void> {
      const payload = {
        residentId: this.$route.params.resident_id,
        suggestionId: suggestion.id,
        status: "Fulfilled",
      }
      try {
        await this.$store.dispatch(
          "dataSuggestions/sendSuggestionResponse",
          payload
        )
      } catch (err) {
        console.error(err)
      }
    },
    openPopinToRedirectToBasket(): void {
      console.log('there is no more suggestion catalog')
    },
    onBasketBtnClick(): void {
      this.$router.push({
        name: Routes.ResidentStoreBasket,
        params: {
          resident_id: this.resident_id,
        },
      })
    },
    goToResidentCatalog(): void {
      this.$router.push({
        name: Routes.ResidentCatalog,
        params: {
          resident_id: this.resident_id,
        },
      })
    },
    onSuggestionDelete(suggestion: SuggestionDto): void {
      this.suggestionToDelete = {
          id: suggestion.id,
          title: this.$_getSuggestionTitle(suggestion)
      }
      this.deleteSuggestionPopinVisible = true
    },
    onDeleteSuggestionPopinCancel(): void {

    },
    onDeleteSuggestionPopinValidate(): void {
      this.deleteSuggestion(this.suggestionToDelete)
    },
    async deleteSuggestion(suggestion: null | Object): Promise<void> {
        const payload = {
          residentId: this.$route.params.resident_id,
          suggestionId: _.get(suggestion, 'id')
        }
        try {
          await this.$store.dispatch(
            "dataSuggestions/deleteSuggestion",
            payload
          )
        }
        catch (error) {
          this.showErrorToast(`Unable to delete suggestion: ${error}`)
        }
    },
    onClickBtnToReply(suggestion: SuggestionDto): void {
      this.dataPopinDealing = suggestion
      this.modelPopinDealing = true
    },
    showErrorToast(text: string) {
      this.$toasted.show(text, {
        theme: "hx-toasted",
        position: "hx-bottom-left",
        duration: null,
        action: [
          {
            text: "Fermer",
            onClick: (e: Event, to: ToastObject) => {
              to.goAway(0)
            },
          },
        ],
      })
    },
  },
})
