






















import Vue from 'vue'

import Routes from "@/constants/routes"

import { HPopin, HBtn } from "@happytal/bo-ui-library"

export default Vue.extend({
    model: {
        prop: 'value',
        event: 'input'
    },

    components: {
        HPopin,
        HBtn,
    },

    data() {
        return {
            popinTransitionIsDone: false,
        }
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        currentResidentId(): string {
            return this.$route.params.resident_id
        },
    },

    methods: {
        closeDialog(): void {
            this.$emit('input', false)
        },
        onCatalogBtnClick(): void {
            this.closeDialog()
            const sto = setTimeout(() => {
                this.$router.push({
                    name: Routes.ResidentCatalog,
                    params: {
                        resident_id: this.currentResidentId
                    }
                })
                clearTimeout(sto)
            }, 250)
        },
        onBasketBtnClick(): void {
            this.closeDialog()
            const sto = setTimeout(() => {
                this.$router.push({
                    name: Routes.ResidentStoreBasket,
                    params: {
                        resident_id: this.currentResidentId,
                    },
                })
                clearTimeout(sto)
            }, 250)
        },
    }
})
